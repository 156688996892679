<template>
  <v-input
    v-if="visible"
    v-model="value"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    @blur="handleBlur"
  >
    <v-field
      v-bind="$attrs"
      :label="label"
    >
      <template #append-inner>
        <mtf-field-icon
          :meta="meta"
          :has-errors="hasErrors"
        />
      </template>

      <div class="w-100">
        <v-toolbar
          class="w-100"
          density="compact"
          rounded
          color="transparent"
        >
          <v-spacer />
          <v-slide-x-reverse-transition leave-absolute>
            <v-chip
              v-if="hasErrors()"
              class="mt-2"
              color="error"
              label
            >
              <v-icon
                start
                icon="mdi-alert-circle-outline"
              />
              Contains Errors
            </v-chip>
          </v-slide-x-reverse-transition>
        </v-toolbar>

        <!-- the dependent uischema will be rendered here -->
        <v-card
          v-if="uischema?.fields?.length"
          flat
          class="mb-4"
          v-bind="uischema?.options"
        >
          <v-toolbar
            v-if="useValues(rowLabel, null, item)"
            flat
            density="compact"
            color="transparent"
            :title="useValues(rowLabel, null, item)"
          />

          <v-expand-transition>
            <v-card-text class="my-n4">
              <div
                v-for="field in uischema.fields"
                :key="`item-${field.name}`"
                class="my-4"
              >
                <component
                  :is="is(field, getFieldSchema(field.name, schema), item)"
                  v-bind="bind(field, schema?.items, item)"
                  :name="`${name}.${field.name}`"
                />
              </div>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </div>
    </v-field>
  </v-input>
</template>

<script>
import { defineComponent } from 'vue';
import { useMatterificFieldArray } from '#imports';
import { components } from '@matterific/composables/useMatterificFields';
import { includes } from 'lodash-es';
import { useValues } from '@matterific/utils';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldGroup',
  components: {
    ...components,
    MtfFieldIcon
  },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    uischema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ---
    min: Number,
    max: Number,
    rowLabel: [String, Function, Object, Array],
    sortable: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],

  // ----------------

  setup(props, context) {
    const {
      meta,
      value,
      errors,
      allErrors,
      handleBlur,
      validate,
      hasErrors,
      getFieldSchema,
      is,
      bind,
      model,
      add,
      remove,
      move,
      swap,
      toggle
    } = useMatterificFieldArray(props.name, props.schema, context);

    return {
      meta,
      value,
      errors,

      allErrors,
      handleBlur,
      validate,
      hasErrors,
      model,
      add,
      remove,
      move,
      swap,
      toggle,
      useValues,
      includes,
      getFieldSchema,
      is,
      bind
    };
  }
});
</script>
